import store from '@/store'
import htmlToPdfmake from 'html-to-pdfmake'
import generateWarrantyPdf from './generateWarrantyPdf'

export const s3url = "https://izzifast-installer-app.s3.eu-west-2.amazonaws.com/";

export async function resolveWarrantyTemplate(template, installation, lang) {

  function replaceVars (text) {
    const device = serializeParams(attachTemplate(installation.customDevice))
    // device
    device.schemaVersion.parametersSchema.forEach(field => {        
      const re = new RegExp(`{{${field.name}}}`, 'g');
      text = text.replace(re, device.parameters[field.name]);
    })
  
    // installer
    Object.entries(installation.installer).forEach(entry => {
      const re = new RegExp(`{{installer.${entry[0]}}}`, 'g');
      text = text.replace(re, entry[1]);
    })
  
    // installation
    Object.entries(installation).forEach(entry => {
      const re = new RegExp(`{{installation.${entry[0]}}}`, 'g');
      text = text.replace(re, entry[0] == 'installationDate' ? new Date(entry[1]).toLocaleDateString() : entry[1]);
    })

    const re = new RegExp(`{{pagebreak}}`, 'g');
    text = text.replace(re, ' ');
    return text
  }
  
  // signatures
  const device = attachTemplate(installation.customDevice)
  let workingContent = template.content
  if (installation.survey?.data) {
    device.schemaVersion.installationFormSchema.forEach((step, stepIndex) => {
      step.schema.forEach(field => {     
          if (field.type == 'signature') {
            const re = new RegExp(`{{signature.${field.imageName}}}`, 'g');
            const replace = installation.survey.data[stepIndex][field.imageName]
            workingContent = workingContent.replace(re, replace);
          }   
        })
    }) 
  }

  const content = htmlToPdfmake(
    workingContent, 
    {
      defaultStyles: {ul: {marginBottom: 0}, p: {margin: [0, 0, 5, 0]}, th: {bold:true, fillColor: process.env.VUE_APP_MAIN_COLOR, color: '#fff'}},
      replaceText: replaceVars,
      tableAutoSize: true
    },
  )
  return await generateWarrantyPdf(
    installation, 
    template.showDataTable,
    template.tableParameters, 
    content, 
    template.showServiceLog,
    lang
  ) 
}

export function resolveWarrantyAttachments (attachments, installation) {
  const device = serializeParams(attachTemplate(installation.customDevice))
  const schema = device.schemaVersion.parametersSchema
  const parameters = device.parameters

  return attachments.filter(attachment => {
    if (!attachment.conditionalLogic || !attachment.conditionalLogic[0].length ) return true
    else {
      let orSatisfied = false
      attachment.conditionalLogic.forEach(or => {
        let andSatisfied = true
        or.forEach(and => {
          if (!and.field) return true
          const type = schema.find(field => {return field.name == and.field}).type
          if (type == 'string') {
            const conditionValue = and.value[0] === "" ? null : and.value[0]
            const fieldValue = parameters[and.field] === "" ? null : parameters[and.field]
            andSatisfied *= and.operator == 'in' ? fieldValue == conditionValue : fieldValue != conditionValue
          } else {
            andSatisfied *= and.operator == 'in' ? and.value.includes(parameters[and.field]) : !and.value.includes(parameters[and.field])
          }
        })
        orSatisfied += andSatisfied
      })
      return orSatisfied
    }
  }).map(attachment => {
    const title = attachment.type == 'internal' && attachment.file ? attachment.file.name : attachment.name
    const href = attachment.type == 'internal' && attachment.file ? `${s3url}${attachment.file.path}` : attachment.path
    return {href, title}
  })
}

export function attachTemplate (device, special = false) {
  if (!device) return null
  const deviceTemplates = special ? store.state.specialDeviceTemplates : store.state.deviceTemplates
  const deviceTemplate = deviceTemplates.find(template => {return template.id == device.deviceTemplate.id})
  if (deviceTemplate) {
    const schemaVersion = deviceTemplate.versions.find(version => {return version.id == device.schemaVersion.id})
    return {...device, deviceTemplate, schemaVersion}
  } else {
    return device
  }
}

export function base64ToFile (base64, filename, mime) {
  //const arr = base64.split(',')
  //const mime = arr[0].match(/:(.*?);/)[1]
  const binary = atob(base64)
  
  let array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }

  return new File([new Uint8Array(array)], filename, { type: mime })
}

export function createFiles (fileDefs, deviceId, data = null, date = null) {
  return fileDefs.map((fileDef) => {
    const filename = `${deviceId}${date ? '-'+date : ''}-${fileDef.label}.${fileDef.type === 'image/jpeg' ? 'jpg' : 'pdf'}`
    if (data) {
      const key = `${labelToCamelCase(fileDef.label)}${fileDef.type === 'image/jpeg' ? 'Image' : ''}`
      data[key] = `${s3url}${filename}`
    }
    return {filename, base64: fileDef.base64, type: fileDef.type}
  })
}

export async function uploadFile (uploadUrl, base64, type) {
  let binary =  atob(base64)
  let array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  let blobData = new Blob([new Uint8Array(array)], {type: type})
  let res = await fetch(uploadUrl, {
    method: 'PUT',
    body: blobData,
    headers: {
      'Content-Type': type,
      'Host': 'izzifast-installer-app.s3.eu-west-2.amazonaws.com',
      'x-amz-acl': 'public-read'
    },
    multipartDisabled: true
  })
  console.log(res, type)
}

export async function getIDB(dbName = "loopee") {
  return new Promise((resolve, reject) => {

    let request = window.indexedDB.open(dbName, 1);

    request.onerror = e => {
      console.log('Error opening db', e);
      reject('Error');
    };

    request.onsuccess = e => {
      console.log('db-opened')
      resolve(e.target.result);
    };

    request.onupgradeneeded = e => {
      console.log('onupgradeneeded');
      let db = e.target.result;
      let requests = db.createObjectStore('requests', { autoIncrement: true, keyPath:'id' });
      let forms = db.createObjectStore('forms', { autoIncrement: true, keyPath:'id' });
      console.log(db, requests, forms)
    };
  });
}

export async function addToIDB(db, tableName, request) {
  return new Promise((resolve) => {

    let result = {}
    let trans = db.transaction([tableName],'readwrite');
    trans.oncomplete = e => {
      result["trans"] = e
      resolve(result)
    };

    let store = trans.objectStore(tableName);
    let req = store.put(request);
    req.onsuccess = e => {
      result["req"] = e
    }
  });
}

export async function getFromIDB(db, tableName) {
  return new Promise((resolve) => {

    let trans = db.transaction([tableName],'readonly');
    trans.oncomplete = () => {
      resolve(requests);
    };

    let store = trans.objectStore(tableName);
    let requests = [];

    store.openCursor().onsuccess = e => {
      let cursor = e.target.result;
      if (cursor) {
        requests.push(cursor.value)
        cursor.continue();
      }
  };

  });
}

export async function deleteFromIDB(db, tableName, id) {
  return new Promise((resolve) => {
    let trans = db.transaction([tableName],'readwrite');
    trans.oncomplete = e => {
      resolve(e);
    };

    let store = trans.objectStore(tableName);
    store.delete(id);
  });
}

export async function clearIDB(db, tableName) {
  return new Promise((resolve) => {
    let trans = db.transaction([tableName],'readwrite');
    trans.oncomplete = e => {
      resolve(e);
    };

    let store = trans.objectStore(tableName);
    store.clear();
  });
}

export function labelToCamelCase(label) {
  const words = label.split('-').map((word, index) => {
    if (index > 0) return word.charAt(0).toUpperCase() + word.slice(1)
    else return word
  })
  return words.join('')
}

export function errorNotification(that, err) {
  that.$notify({
    title: "Podczas próby połączenia z bazą danych wystąpił błąd:",
    text: err.response.data.detail,
    type: 'error',
    duration: 10000
  })
}

export function getDeviceSerialNumber(device) {
  const schema = device.schemaVersion ? device.schemaVersion.parametersSchema : device.deviceTemplate.activeVersion.parametersSchema
  const idField = schema.find(field => {return field.barcodeScanner})
  return idField ? device.parameters[idField.name] : ""
}

export function applyFormConditionalLogic(schema, device, fields, values) {
  return schema.filter(item => {
    if (!item.conditionalLogic || !item.conditionalLogic[0].length ) return true
    else {
      let value = null
      let type = null
      let formField = null
      let deviceField = null
      let orSatisfied = false
      item.conditionalLogic.forEach(or => {
        let andSatisfied = true
        or.forEach(and => {
          if (!and.field) return true
          const source = and.field.split('.')[0]
          const andField = and.field.split('.')[1]
          if (source == 'form') {
            formField = fields.find(field => {return field.name == andField})
            if (formField) {
              type = formField.type
              value = values
            } else return true
          } else if (source == 'device') {
            const schemaVersion = device.schemaVersion || device.deviceTemplate.activeVersion
            deviceField = schemaVersion.parametersSchema.find(field => {return field.name == andField})
            if (deviceField) {
              type = deviceField.type
              value = device.parameters
            } else return true
          }
          if (type == 'string') {
            const conditionValue = and.value[0] === "" ? null : and.value[0]
            const fieldValue = value[andField] === "" ? null : value[andField]
            andSatisfied *= and.operator == 'in' ? fieldValue == conditionValue : fieldValue != conditionValue
          } else {
            andSatisfied *= and.operator == 'in' ? and.value.includes(value[andField]) : !and.value.includes(value[andField])
          }
        })
        orSatisfied += andSatisfied
      })
      return orSatisfied
    }
  })
}

export function serializeParams(device) {
  if (device.params) {
    const parameters = {}
    device.params.forEach(param => {
      parameters[param.name] = param.value
    })
    device.parameters = parameters
  }
  return device
}

export function deserializeParams(device) {
  if (device.parameters) {
    const params = []
    Object.entries(device.parameters).forEach(entry => {
      params.push({name: entry[0], value: entry[1]})
    })
    device.params = params
  }
  return device
}
