import Vuex from "vuex";
import { api } from "@/assets/js/api-client.js";
import { attachTemplate, serializeParams } from "@/assets/js/helpers.js";

const defaultInstallations = {
  data: null,
  pagination: {total: '-', currentPage: 1},
  query: '',
  pending: {data: null, pagination: {total: '-'}, query: ''},
  accepted: {data: null, pagination: {total: '-'}, query: ''},
  rejected: {data: null, pagination: {total: '-'}, query: ''},
  no_warranty: {data: null, pagination: {total: '-'}, query: ''}
}

const defaultInspections = {
  pending: {data: null, pagination: {total: '-'}, query: ''},
  scheduled: {data: null, pagination: {total: '-'}, query: ''},
  done: {data: null, pagination: {total: '-'}, query: ''}
}

const defaultServices = {
  pending: {data: null, pagination: {total: '-'}, query: ''},
  done: {data: null, pagination: {total: '-'}, query: ''},
  rejected: {data: null, pagination: {total: '-'}, query: ''}
}

export default Vuex.createStore({
  state: {
    loading: false,
    user: null,
    role: null,
    exp: null,
    organisation: process.env.VUE_APP_ORG_ID,
    organisations: null,
    aspView: false,
    asps: null,
    device: null,
    deviceTemplates: null,
    specialDeviceTemplates: null,
    installations: defaultInstallations,
    currentInstallation: null,
    inspections: defaultInspections,
    services: defaultServices,
    incomingInspections: null,
    pendingServices: null,
    folders: null,
    aspModalSwitch: false,
    requests: []
  },
  mutations: {
    SET_LOADING(state, enabled) {
      state.loading = enabled;
    },
    SET_ASP_VIEW(state, enabled) {
      state.aspView = enabled;
    },
    SAVE_USER(state, user) {
      state.user = user;
    },
    SAVE_ROLE(state, role) {
      state.role = role;
    },
    SAVE_EXP(state, exp) {
      state.exp = exp;
    },
    SAVE_ORGANISATION(state, organisation) {
      state.organisation = organisation;
    },
    SAVE_ORGANISATIONS(state, organisations) {
      state.organisations = organisations;
    },
    CLEAR_USER(state) {
      state.user = null;
      state.role = null;
      state.device = null;
      state.installations = defaultInstallations;
      state.currentInstallation = null;
      state.deviceTemplates = null;
      state.inspections = defaultInspections;
      state.services = defaultServices;
    },
    CLEAR_ROLE(state) {
      state.role = null;
    },
    SAVE_ASPS(state, asps) {
      state.asps = asps;
    },
    SAVE_DEVICE(state, device) {
      state.device = serializeParams(device);
    },
    SAVE_DEVICE_TEMPLATES(state, deviceTemplates) {
      state.deviceTemplates = deviceTemplates;
    },
    SAVE_SPECIAL_DEVICE_TEMPLATES(state, deviceTemplates) {
      state.specialDeviceTemplates = deviceTemplates;
    },
    UPDATE_DEVICE_TEMPLATE(state, deviceTemplate) {
      if (state.deviceTemplates) {
        const index = state.deviceTemplates.findIndex((template) => {return template.id == deviceTemplate.id});
        if (index !== -1) state.deviceTemplates[index] = deviceTemplate
      }
    },
    SAVE_INSTALLATIONS(state, installations) {
      state.installations = installations;
    },
    SAVE_ADMIN_INSTALLATIONS(state, {status, installations}) {
      if (status) state.adminInstallations[status] = installations;
      else state.installations = installations;
    },
    SAVE_CURRENT_INSTALLATION(state, installation) {
      state.currentInstallation = installation;
    },
    ADD_INSTALLATION(state, installation) {
      if (state.installations) state.installations.push(installation)
      else state.installations = [installation]
    },
    DELETE_INSTALLATION(state, installation) {
      if (state.installations) {
        const index = state.installations.findIndex((inst) => {return inst.id === installation.id});
        if (index !== -1) state.installations.splice(index, 1)
      }
    },
    UPDATE_INSTALLATION(state, installation) {
      if (state.installations) {
        const index = state.installations.findIndex((inst) => {return inst.id === installation.id});
        if (index !== -1) state.installations[index] = installation
      }
    },
    SAVE_INSPECTIONS(state, inspections) {
      state.inspections = inspections;
    },
    SAVE_SERVICES(state, services) {
      state.services = services;
    },
    UPDATE_SERVICE(state, service) {
      if (state.services) {
        const index = state.services.findIndex((serv) => {return serv.id == service.id});
        if (index !== -1) state.services[index] = service
      }
    },
    SAVE_COLLECTION(state, {entity, storeKey, items}) {
      if (storeKey) state[entity][storeKey] = items;
      else state[entity] = {...state[entity], ...items};
    },
    ADD_REQUEST(state, request) {
      state.requests.push(request)
    },
    REMOVE_REQUEST(state, index) {
      state.requests.splice(index, 1)
    },
    SAVE_FOLDERS(state, folders){
      state.folders = folders
    },
    SAVE_PENDING_SERVICES(state, pendingServices){
      state.pendingServices = pendingServices
    },
    SAVE_INCOMING_INSPECTIONS(state, incomingInspections){
      state.incomingInspections = incomingInspections
    }
  },
  actions: {
    setLoading({ commit }, enabled) {
      commit("SET_LOADING", enabled);
    },
    setAspView({ commit }, enabled) {
      commit("SET_ASP_VIEW", enabled);
    },
    saveUser({ commit }, user) {
      commit("SAVE_USER", user);
    },
    saveRole({ commit }, role) {
      commit("SAVE_ROLE", role);
    },
    saveExp({ commit }, exp) {
      commit("SAVE_EXP", exp);
    },
    saveOrganisation({ commit }, organisation) {
      commit("SAVE_ORGANISATION", organisation);
    },
    clearUser({ commit }) {
      commit("CLEAR_USER");
      commit("CLEAR_ROLE");
    },
    getAsps ({commit, state}) {
      return api.get(`/organisations/${state.organisation}/users?roles[]=$(0)|ROLE_ASP`).then(result => {
        commit('SAVE_ASPS', result.data)
        console.log(result.data)
      }).catch(err => {
        console.log(err)
      })
    },
    saveAsps({ commit }, asps) {
      commit("SAVE_ASPS", asps);
    },
    saveDevice({ commit }, device) {
      commit("SAVE_DEVICE", device);
    },
    getDevice({ commit }, data ){
      return new Promise ((resolve, reject) => {
        if (data.deviceTemplate) {
          const searchParams = new URLSearchParams()
          searchParams.append('deviceTemplate.id', data.deviceTemplate.id)
          data.deviceTemplate.activeVersion.parametersSchema
            .filter(field => {return field.barcodeScanner})
            .forEach(field => {searchParams.append(`parameterOrExact[${field.name}]`, data.id)})
          
          if (data.deviceExtension) searchParams.append('exists[deviceExtension]', 1)

          const query = searchParams.toString()
          api.get(`/custom_devices?${query}`).then(result => {
            if (result.data.length) {
              commit('SAVE_DEVICE', attachTemplate(result.data[0]))
              resolve({status: 'found'})
            } else {
              resolve({status: 'not-found'})
            }
          }).catch(err => {
            console.log(err)
            reject(err)
          })
        } else {
          api.get(`/custom_devices/${data.id}`).then(result => {
            if (result.data) {
              commit('SAVE_DEVICE', result.data)
              resolve({status: 'found'})
            }
          }).catch(err => {
            console.log(err)
            if (err.response.data.detail == 'Not Found') {
              resolve({status: 'not-found'})
            }
            reject(err)
          })
        }
      })
    },
    getDeviceTemplates({ commit, state }, all = false) {
      const endpoint = all ? '/device_templates' : `/organisations/${state.organisation}/device_templates`
      return api.get(endpoint).then(result => {
        console.log(result)
        commit("SAVE_DEVICE_TEMPLATES", result.data);
      }).catch((error) => {
        console.log(error)
      })
    },
    getSpecialDeviceTemplates({ commit }) {
      return api.get('/device_templates?isSpecial=true').then(result => {
        console.log(result)
        commit("SAVE_SPECIAL_DEVICE_TEMPLATES", result.data);
      }).catch((error) => {
        console.log(error)
      })
    },
    updateDeviceTemplate({ commit }, deviceTemplate) {
      commit("UPDATE_DEVICE_TEMPLATE", deviceTemplate);
    },
    getInstallations({ commit, state }) {
      return new Promise ((resolve, reject) => {
        api.get(`/users/${state.user.id}/installations?order[installationDate]&order[id]`).then(result => {
          let installations = result.data
          console.log(result.data)
          api.get(`/users/${state.user.id}/received_installations`).then(result => {
            let received = result.data
            installations.push(...received)
            installations = installations.map(inst => {return {...inst, customDevice: attachTemplate(inst.customDevice)}})
            installations.sort((a, b) => { return new Date(b.installationDate).getTime() - new Date(a.installationDate).getTime() })

            let services = [], inspections = []
            installations.forEach(inst => {
              const installation = Object.assign({}, inst)
              if (installation.customDevice) {
                const installationDevice = installation.customDevice

                delete installation.customDevice
                installationDevice.installation = installation

                const deviceServices = installationDevice.services
                const deviceInspections = installationDevice.inspections
                delete installationDevice.services
                delete installationDevice.inspections

                const preparedServices = deviceServices.map(serv => {return {...serv, customDevice: installationDevice}})
                services.push(...preparedServices)
                const preparedInspections = deviceInspections.map(insp => {return {...insp, customDevice: installationDevice}})
                inspections.push(...preparedInspections)
              }
            });

            api.get(`/users/${state.user.id}/services`).then(result => {

              const foreignServices = result.data.filter((fs) => {
                return !services.find(service => {return fs.id == service.id})
              })
              services.push(...foreignServices)

              inspections = inspections.sort((a,b) => {
                return new Date(a.soonestDate).getTime() - new Date(b.soonestDate).getTime()
              })

              services = services.sort((a,b) => {
                return new Date(a.serviceStartDate).getTime() - new Date(b.serviceStartDate).getTime()
              })

              commit("SAVE_INSTALLATIONS", installations);
              commit("SAVE_INSPECTIONS", inspections);
              commit("SAVE_SERVICES", services);

            }).catch((error) => {
              console.log(error)
              reject()
            })
            resolve()
          }).catch((error) => {
            console.log(error)
            reject()
          })
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    getCollection({ commit, state }, {entity, key, status, page, query}) {
      return new Promise ((resolve, reject) => {
        const store = state[entity]
        const storeKey = key ? key : (status ? status : null)
        const currentQuery = storeKey ? store[storeKey].query : store.query
        query = typeof query !== "undefined" ? query : currentQuery
        const pagination = storeKey ? store[storeKey].pagination : store.pagination
        page = page ? page : pagination.currentPage

        let statusQuery = status ? status.split(',').map(s => {return `&status[]=${s}`}).join('') : ''
       
        const sortingValues = {
          'installations': 'installationDate',
          'inspections': 'soonestDate',
          'services': 'serviceStartDate'
        }

        api.get(`${['admin','superadmin'].includes(state.role.name) ? `/organisations/${state.organisation}` : ''}/${entity}.jsonld?order[${sortingValues[entity]}]&page=${page}${statusQuery}${query}`).then(result => {
          const data = result.data['hydra:member']
          const pagination = result.data['hydra:view']
          pagination['total'] = result.data['hydra:totalItems']
          this.numbersOfItemsOnPage = 12
          pagination['currentPage'] = page
          pagination['from'] = (page - 1) * this.numbersOfItemsOnPage + 1
          pagination['to'] = Math.min(page * this.numbersOfItemsOnPage, pagination['total'])
          commit(`SAVE_COLLECTION`, {entity, storeKey, items: {data, pagination, query}});
          resolve()
        }).catch((error) => {
          console.log(error)
          reject()
        })
      })
    },
    async getNotifications({ commit}){
      // get pending srvices
      let result = await api.get(`/services.jsonld?status=spare_parts_sent&itemsPerPage=0`)
      const pendingServices = result.data["hydra:totalItems"]
      commit("SAVE_PENDING_SERVICES", pendingServices);

      // get pending inspectionds
      const today = new Date()
      const limit = new Date(today.getTime() + (30 * 24 * 60 * 60000))
      result = await api.get(`/inspections.jsonld?status=pending&soonestDate[before]=${limit.toISOString()}&itemsPerPage=0`)
      const incomingInspections = result.data["hydra:totalItems"]
      commit("SAVE_INCOMING_INSPECTIONS", incomingInspections);
    },
    saveCurrentInstallation({ commit }, installation) {
      commit("SAVE_CURRENT_INSTALLATION", installation);
    },
    addInstallation({ commit }, installation) {
      commit("ADD_INSTALLATION", installation);
    },
    deleteInstallation({ commit }, installation) {
      commit("DELETE_INSTALLATION", installation);
    },
    updateInstallation({ commit }, installation) {
      commit("UPDATE_INSTALLATION", installation);
    },
    getInspections({ commit, state }) {
      return api.get(`/users/${state.user.id}/inspections?order[soonestDate]`).then(result => {
        console.log(result)
        commit("SAVE_INSPECTIONS", result.data);
      }).catch((error) => {
        console.log(error)
      })
    },
    getServices({ commit, state }) {
      return api.get(`/users/${state.user.id}/services?order[serviceStartDate]`).then(result => {
        console.log(result)
        commit("SAVE_SERVICES", result.data);
      }).catch((error) => {
        console.log(error)
      })
    },
    updateService({ commit }, service) {
      commit("UPDATE_SERVICE", service);
    },
    getFolders({ commit }, lang) {
      return api.get(`/organisations/${process.env.VUE_APP_ORG_ID}/support_materials?language=${lang}`).then(result => {
        console.log(result)
        commit("SAVE_FOLDERS", result.data);
      }).catch((error) => {
        console.log(error)
      })
    },
    getOrganisations({commit}) {
      return api.get('/organisations').then(result => {
        commit("SAVE_ORGANISATIONS", result.data)
      }).catch((error) => {
        console.log(error)
      })
    },
    async checkAsp ({state, dispatch}) {
      if (parseInt(process.env.VUE_APP_FORCE_ASP) && state.role.name == 'installer' && !state.user.asp) {
        if (!state.asps) await dispatch('getAsps')
        if (state.asps.length) {
          state.aspModalSwitch = !state.aspModalSwitch
          return true
        }
      }
      return false
    },
    addRequest({ commit }, request) {
      commit("ADD_REQUEST", request);
    },
    removeRequest({ commit }, index) {
      commit("REMOVE_REQUEST", index);
    },
  },
  modules: {},
});
