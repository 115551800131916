<template>
  <svg
      class="mx-auto h-8 w-auto"
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="100 430 830 160"
  >
      <polyline
      class="cls-1"
      points="151.85 559.39 151.85 584.14 249.62 584.14 249.62 555.15 190.48 555.15 254 464.31 254 440.15 153.95 440.15 153.65 469.13 215.08 469.13 151.85 559.39"
      />
      <polyline
      class="cls-1"
      points="271.59 559.39 271.59 584.14 369.35 584.14 369.35 555.15 310.22 555.15 373.73 464.31 373.73 440.15 273.68 440.15 273.38 469.13 334.82 469.13 271.59 559.39"
      />
      <polyline
      class="cls-2"
      points="482.83 492.03 482.83 469.22 551.41 469.22 571.97 440.35 453.96 440.35 453.96 583.35 482.83 583.35 482.83 520.89 517.3 520.89 536.93 492.03 482.83 492.03"
      />
      <rect class="cls-2" x="853.2" y="493.11" width="28.87" height="89.08" />
      <path
      class="cls-3"
      d="M748.94,444.19a40.33,40.33,0,0,0-40.2,37.75c-1.45,23.51,17.54,42.79,41.1,42.79h32.27c6.07,0,11,6.36,11,11.63s-4.17,12.49-11,12.49H600.67a.06.06,0,0,0-.06,0v28.76a.06.06,0,0,0,.06.06H782.11c20.09,0,38.38-19.72,38.38-41.36s-17.94-40.5-38.38-40.5H749.4c-6.1,0-11.46-4.58-11.84-10.66a11.42,11.42,0,0,1,11.38-12.14H915.25L935,444.24a0,0,0,0,0,0-.05h-186"
      />
      <polyline
      class="cls-2"
      points="657.42 529.18 692.68 529.18 669.8 494.27 669.8 470.07 653.94 470.07 635.17 441.43 606.07 441.43 527.42 557.36 544.56 557.36 544.56 582.86 574.21 582.86 574.21 540.41 620.55 470.64 657.42 529.18"
      />
      <rect
      class="cls-1"
      x="396.43"
      y="439.86"
      width="29.65"
      height="29.36"
      />
      <rect
      class="cls-1"
      x="100.96"
      y="439.86"
      width="29.65"
      height="29.36"
      />
      <rect
      class="cls-1"
      x="396.43"
      y="492.03"
      width="29.65"
      height="91.82"
      />
      <rect
      class="cls-1"
      x="100.96"
      y="492.03"
      width="29.65"
      height="91.82"
      />
  </svg>
</template>

<script>
  export default {}
</script>

<style scoped lang="scss">
.cls-1 {
  fill: #979797;
}
.cls-2 {
  fill: #4e4e4d;
}
.cls-3 {
  fill: #99be3a;
}
</style>
